import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { DEFAULT_CATALOG, DEFAULT_LOCALE, SupportedLocale } from '../constants/locals'
import {
    af,
    ar,
    ca,
    cs,
    da,
    de,
    el,
    en,
    es,
    fi,
    fr,
    he,
    hu,
    id,
    it,
    ja,
    ko,
    nl,
    no,
    pl,
    pt,
    ro,
    ru,
    sr,
    sv,
    sw,
    tr,
    uk,
    vi,
    zh,
} from 'make-plural/plurals'
import { PluralCategory } from 'make-plural/plurals'
import { ReactNode, useEffect } from 'react'

type LocalePlural = {
    [key in SupportedLocale]: (n: number | string, ord?: boolean) => PluralCategory
}

const plurals: LocalePlural = {
    'af-ZA': af,
    'ar-SA': ar,
    'ca-ES': ca,
    'cs-CZ': cs,
    'da-DK': da,
    'de-DE': de,
    'el-GR': el,
    'en-US': en,
    'es-ES': es,
    'fi-FI': fi,
    'fr-FR': fr,
    'he-IL': he,
    'hu-HU': hu,
    'id-ID': id,
    'it-IT': it,
    'ja-JP': ja,
    'ko-KR': ko,
    'nl-NL': nl,
    'no-NO': no,
    'pl-PL': pl,
    'pt-BR': pt,
    'pt-PT': pt,
    'ro-RO': ro,
    'ru-RU': ru,
    'sr-SP': sr,
    'sv-SE': sv,
    'sw-TZ': sw,
    'tr-TR': tr,
    'uk-UA': uk,
    'vi-VN': vi,
    'zh-CN': zh,
    'zh-TW': zh,
    pseudo: en,
}

export async function dynamicActivate(locale: SupportedLocale) {
    i18n.loadLocaleData(locale, { plurals: () => plurals[locale] })
    // There are no default messages in production; instead, bundle the default to save a network request:
    // see https://github.com/lingui/js-lingui/issues/388#issuecomment-497779030
    const catalog =
        locale === DEFAULT_LOCALE ? DEFAULT_CATALOG : await import(`${process.env.REACT_APP_LOCALES}/${locale}`)
    i18n.load(locale, catalog.messages)
    i18n.activate(locale)
}

interface ProviderProps {
    locale: SupportedLocale
    forceRenderAfterLocaleChange?: boolean
    onActivate?: (locale: SupportedLocale) => void
    children: ReactNode
}

export function Provider({ locale, forceRenderAfterLocaleChange = true, onActivate, children }: ProviderProps) {
    useEffect(() => {
        dynamicActivate(locale)
            .then(() => onActivate?.(locale))
            .catch((error) => {
                console.error('Failed to activate locale', locale, error)
            })
    }, [locale, onActivate])

    return (
        <I18nProvider forceRenderOnLocaleChange={forceRenderAfterLocaleChange} i18n={i18n}>
            {children}
        </I18nProvider>
    )
}
