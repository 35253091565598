/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  VaultHubCallee,
  VaultHubCalleeInterface,
} from "../../../libraries/Constants.sol/VaultHubCallee";

const _abi = [
  {
    inputs: [],
    name: "GET_LABEL_EXIST_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GET_LABEL_NAME_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HAS_MINTED_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HAS_REGISTER_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INIT_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MINT_SAVE_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "QUERY_BY_INDEX_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "QUERY_BY_NAME_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "QUERY_PRIVATE_VAULT_ADDRESS_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SAVE_WITHOUT_MINT_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "TOTAL_SAVED_ITEMS_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x61017c61003a600b82828239805160001a60731461002d57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600436106100b35760003560e01c8063809f2f021161007b578063809f2f021461011b5780639bdc03b114610129578063a225217b14610137578063e2b7e2a314610145578063e6a19f9a14610153578063f6bc5baa1461016157600080fd5b806320afd30b146100b857806328258f25146100e35780632ff51e7a146100f157806347a9f343146100ff578063781b498f1461010d575b600080fd5b6100c66395781f1f60e01b81565b6040516001600160e01b0319909116815260200160405180910390f35b6100c663795700ef60e11b81565b6100c6636e8c0dab60e11b81565b6100c66394f82d8160e01b81565b6100c6630692776960e21b81565b6100c66315b2755f60e01b81565b6100c6631abaeca760e31b81565b6100c66379861a0560e01b81565b6100c663560ee72b60e01b81565b6100c66301c190bd60e01b81565b6100c6631596084360e01b8156fea164736f6c634300080c000a";

type VaultHubCalleeConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: VaultHubCalleeConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class VaultHubCallee__factory extends ContractFactory {
  constructor(...args: VaultHubCalleeConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<VaultHubCallee> {
    return super.deploy(overrides || {}) as Promise<VaultHubCallee>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): VaultHubCallee {
    return super.attach(address) as VaultHubCallee;
  }
  override connect(signer: Signer): VaultHubCallee__factory {
    return super.connect(signer) as VaultHubCallee__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): VaultHubCalleeInterface {
    return new utils.Interface(_abi) as VaultHubCalleeInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): VaultHubCallee {
    return new Contract(address, _abi, signerOrProvider) as VaultHubCallee;
  }
}
