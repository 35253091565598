/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  CalleeName,
  CalleeNameInterface,
} from "../../../libraries/Constant.sol/CalleeName";

const _abi = [
  {
    inputs: [],
    name: "GET_LABEL_EXIST_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GET_LABEL_NAME_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GET_PRIVATE_DATA_BY_INDEX_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GET_PRIVATE_DATA_BY_NAME_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HAS_MINTED_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "HAS_REGISTER_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INIT_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "LABEL_IS_EXIST_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "LABEL_NAME_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MINT_SAVE_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "QUERY_BY_INDEX_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "QUERY_BY_NAME_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "QUERY_PRIVATE_VAULT_ADDRESS_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SAVE_WITHOUT_MINTING_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SAVE_WITHOUT_MINT_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "TOTAL_SAVED_ITEMS_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "UPDATE_VALIDATOR_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x61023161003a600b82828239805160001a60731461002d57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600436106101155760003560e01c8063781b498f116100ac578063a960a39b1161007b578063a960a39b146101de578063e1456e7f146101ec578063e2b7e2a3146101fa578063e6a19f9a14610208578063f6bc5baa1461021657600080fd5b8063781b498f146101a6578063809f2f02146101b45780639bdc03b1146101c2578063a225217b146101d057600080fd5b806347a9f343116100e857806347a9f3431461016f57806349b9cad81461017d57806360dbc52f1461018a5780636dbd87f61461019857600080fd5b806320afd30b1461011a57806328258f25146101455780632ff51e7a1461015357806336dbcd0614610161575b600080fd5b6101286395781f1f60e01b81565b6040516001600160e01b0319909116815260200160405180910390f35b61012863795700ef60e11b81565b610128636e8c0dab60e11b81565b610128631c9febff60e21b81565b6101286394f82d8160e01b81565b610128627733f560e71b81565b610128631046f7f960e01b81565b610128630459e85b60e31b81565b610128630692776960e21b81565b6101286315b2755f60e01b81565b610128631abaeca760e31b81565b6101286379861a0560e01b81565b6101286301f8e06360e01b81565b610128633eeaa95360e01b81565b61012863560ee72b60e01b81565b6101286301c190bd60e01b81565b610128631596084360e01b8156fea164736f6c634300080c000a";

type CalleeNameConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: CalleeNameConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class CalleeName__factory extends ContractFactory {
  constructor(...args: CalleeNameConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<CalleeName> {
    return super.deploy(overrides || {}) as Promise<CalleeName>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): CalleeName {
    return super.attach(address) as CalleeName;
  }
  override connect(signer: Signer): CalleeName__factory {
    return super.connect(signer) as CalleeName__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): CalleeNameInterface {
    return new utils.Interface(_abi) as CalleeNameInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): CalleeName {
    return new Contract(address, _abi, signerOrProvider) as CalleeName;
  }
}
