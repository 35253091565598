/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  PrivateVaultTypeHashs,
  PrivateVaultTypeHashsInterface,
} from "../../../libraries/Constants.sol/PrivateVaultTypeHashs";

const _abi = [
  {
    inputs: [],
    name: "PRIVATE_DOMAIN_NAME",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_DOMAIN_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_DOMAIN_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_GET_PRIVATE_DATA_BY_INDEX_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_GET_PRIVATE_DATA_BY_NAME_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_LABEL_EXIST_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_LABEL_NAME_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_SAVE_WITHOUT_MINTING_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PRIVATE_UPDATE_VALIDATOR_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x61029561003a600b82828239805160001a60731461002d57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe730000000000000000000000000000000000000000301460806040526004361061009d5760003560e01c80632bafa9a7116100705780632bafa9a71461017357806370aae6131461019a5780637f3ae1ef146101c1578063c1fe5365146101e8578063e0b6f6e51461020f57600080fd5b80630414832c146100a257806308e97072146100dc57806317688b85146101035780632990395a1461012a575b600080fd5b6100c97f91fb9dd060bd9ffe42a43373e9de88b3a9b106cbce07f242fd6f2c4a41ef921d81565b6040519081526020015b60405180910390f35b6100c97f17558919af4007c4fb94572ba8e807922ff7db103814e127ad21ef481ca35d9881565b6100c97fb5874d5c3f6f8ad0eddae31287a8b0ff49e374249cd2389616fb828f06f42f6381565b6101666040518060400160405280601981526020017f707269766174655661756c7440736565646c6973742e6f72670000000000000081525081565b6040516100d39190610233565b6100c97f5e9a0e1424c7f33522faa862eafa09a676e96246da16c8b58d5803ba8010584f81565b6100c97fcbb2475c190d2e287f7de56c688846f7612f70b210a3856ad34c475cbad0dda781565b6100c97fdad980a10e49615eb7fc5d7774307c8f04d959ac46349850121d52b1e409fc1e81565b6100c97f79c473821b1882439e653292df5add05615ab1a78b695620f6cf37ab0fb6dbbc81565b610166604051806040016040528060058152602001640312e302e360dc1b81525081565b600060208083528351808285015260005b8181101561026057858101830151858201604001528201610244565b81811115610272576000604083870101525b50601f01601f191692909201604001939250505056fea164736f6c634300080c000a";

type PrivateVaultTypeHashsConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: PrivateVaultTypeHashsConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class PrivateVaultTypeHashs__factory extends ContractFactory {
  constructor(...args: PrivateVaultTypeHashsConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<PrivateVaultTypeHashs> {
    return super.deploy(overrides || {}) as Promise<PrivateVaultTypeHashs>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): PrivateVaultTypeHashs {
    return super.attach(address) as PrivateVaultTypeHashs;
  }
  override connect(signer: Signer): PrivateVaultTypeHashs__factory {
    return super.connect(signer) as PrivateVaultTypeHashs__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): PrivateVaultTypeHashsInterface {
    return new utils.Interface(_abi) as PrivateVaultTypeHashsInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): PrivateVaultTypeHashs {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as PrivateVaultTypeHashs;
  }
}
