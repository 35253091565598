/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  VaultHubTypeHashs,
  VaultHubTypeHashsInterface,
} from "../../../libraries/Constants.sol/VaultHubTypeHashs";

const _abi = [
  {
    inputs: [],
    name: "VAULTHUB_DOMAIN_NAME",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_DOMAIN_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_DOMAIN_VERSION",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_GET_LABEL_NAME_BY_INDEX_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_HAS_MINTED_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_INDEX_QUERY_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_INIT_VAULT_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_LABEL_EXIST_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_MINT_SAVE_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_NAME_QUERY_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_QUERY_PRIVATE_VAULT_ADDRESS_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_SAVE_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_TOTAL_SAVED_ITEMS_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "VAULTHUB_VAULT_HAS_REGISTER_PERMIT_TYPE_HASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x6103a561003a600b82828239805160001a60731461002d57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600436106100f25760003560e01c80636c47b86f116100965780638353461b116100705780638353461b146102a7578063ce93a6ad146102ce578063dda5a8c7146102f5578063e5bf18dc1461031c57600080fd5b80636c47b86f14610232578063731f9e6c1461025957806378b3859e1461028057600080fd5b80630ddd5615116100d25780630ddd56151461017f57806312acb972146101b05780632689eacd146101d7578063645ca4bb146101fe57600080fd5b8062740f64146100f7578062afc4ed146101315780630ce95df314610158575b600080fd5b61011e7fcdd3cc6eb42396c94a4d5d905327888ade5ae14c59a5d22ae3235b88283c003581565b6040519081526020015b60405180910390f35b61011e7fac1275bd89417f307b1ae27de4967e4910dfab4abd173eb3e6a3352c21ae42fe81565b61011e7fef93604cd5c5e7d35e7ef7d38e1cac9e1cc450e49bc931effd1f65a5a993121d81565b6101a3604051806040016040528060058152602001640312e302e360dc1b81525081565b6040516101289190610343565b61011e7f21b7e085fb49739c78b83ddb0a8a7e4b469211d08958f57d52ff68325943de0481565b61011e7fab4ac209d4a97678c29d0f2f4ef3539a24e0ce6dbd2dd481c818134b61d28ecc81565b6101a3604051806040016040528060158152602001747661756c7468756240736565646c6973742e6f726760581b81525081565b61011e7fbd5bc3ca2c7ea773b900edfe638ad04ce3697bf85885abdbe90a2f7c1266d9ee81565b61011e7ff65e93839555276acb1b1c33eb49dff5fa6a88c6991b9b84b680dc961b85f84781565b61011e7f6c055b4eb43bcfe637041a3adda3d9f2b05d93fc3a54fc8c978e7d0d95e35b6681565b61011e7fdbd66a895de1fdf2e44b84c83cf1e4f482f647eb80397d069bf7763a583ce1a581565b61011e7fbcb00634c612072a661bb64fa073e7806d31f3790f1c827cd20f95542b5af67981565b61011e7f50a5dca0d9658d6eb6282f2d7bdda2a899b962259e2708f7cce8c48021a6348381565b61011e7f5a14c87645febe5840f128409acb12772ff89f3398b05142d7e039c76e0844e881565b600060208083528351808285015260005b8181101561037057858101830151858201604001528201610354565b81811115610382576000604083870101525b50601f01601f191692909201604001939250505056fea164736f6c634300080c000a";

type VaultHubTypeHashsConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: VaultHubTypeHashsConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class VaultHubTypeHashs__factory extends ContractFactory {
  constructor(...args: VaultHubTypeHashsConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<VaultHubTypeHashs> {
    return super.deploy(overrides || {}) as Promise<VaultHubTypeHashs>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): VaultHubTypeHashs {
    return super.attach(address) as VaultHubTypeHashs;
  }
  override connect(signer: Signer): VaultHubTypeHashs__factory {
    return super.connect(signer) as VaultHubTypeHashs__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): VaultHubTypeHashsInterface {
    return new utils.Interface(_abi) as VaultHubTypeHashsInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): VaultHubTypeHashs {
    return new Contract(address, _abi, signerOrProvider) as VaultHubTypeHashs;
  }
}
