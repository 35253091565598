/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  PrivateVaultCallee,
  PrivateVaultCalleeInterface,
} from "../../../libraries/Constants.sol/PrivateVaultCallee";

const _abi = [
  {
    inputs: [],
    name: "GET_PRIVATE_DATA_BY_INDEX_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "GET_PRIVATE_DATA_BY_NAME_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "LABEL_IS_EXIST_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "LABEL_NAME_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SAVE_WITHOUT_MINTING_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "UPDATE_VALIDATOR_PERMIT",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x60e1610039600b82828239805160001a60731461002c57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe730000000000000000000000000000000000000000301460806040526004361060655760003560e01c806336dbcd0614606a57806349b9cad814609457806360dbc52f1460a05780636dbd87f61460ad578063a960a39b1460ba578063e1456e7f1460c7575b600080fd5b6077631c9febff60e21b81565b6040516001600160e01b0319909116815260200160405180910390f35b6077627733f560e71b81565b6077631046f7f960e01b81565b6077630459e85b60e31b81565b60776301f8e06360e01b81565b6077633eeaa95360e01b8156fea164736f6c634300080c000a";

type PrivateVaultCalleeConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: PrivateVaultCalleeConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class PrivateVaultCallee__factory extends ContractFactory {
  constructor(...args: PrivateVaultCalleeConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<PrivateVaultCallee> {
    return super.deploy(overrides || {}) as Promise<PrivateVaultCallee>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): PrivateVaultCallee {
    return super.attach(address) as PrivateVaultCallee;
  }
  override connect(signer: Signer): PrivateVaultCallee__factory {
    return super.connect(signer) as PrivateVaultCallee__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): PrivateVaultCalleeInterface {
    return new utils.Interface(_abi) as PrivateVaultCalleeInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): PrivateVaultCallee {
    return new Contract(address, _abi, signerOrProvider) as PrivateVaultCallee;
  }
}
